import axios from "axios";
import authHeader from "./auth-header";

const API_URL = process.env.VUE_APP_API_URL;

class StatsService {
  getEscalated(client_id, site_id = null, start_date, end_date) {
    if (site_id === -1 || site_id === undefined) site_id = null;

    let url = `${API_URL}stats/escalated?client_id=${client_id}`;

    if (site_id !== null) {
      url += `&site_id=${site_id}`;
    }
    if (start_date !== null && start_date !== undefined) {
      url += `&start_date=${start_date}`;
    }
    if (end_date !== null && end_date !== undefined) {
      url += `&end_date=${end_date}`;
    }

    return axios.get(url, { headers: authHeader() });
  }

  getLetterCount(client_id, site_id, letter_type, start_date, end_date) {
    if (site_id === -1 || site_id === undefined) site_id = null;

    let url = `${API_URL}stats/lettercount?client_id=${client_id}`;

    if (site_id !== null) {
      url += `&site_id=${site_id}`;
    }
    if (letter_type !== null && letter_type !== undefined) {
      url += `&letter_type=${letter_type}`;
    }
    if (start_date !== null && start_date !== undefined) {
      url += `&start_date=${start_date}`;
    }
    if (end_date !== null && end_date !== undefined) {
      url += `&end_date=${end_date}`;
    }

    return axios.get(url, { headers: authHeader() });
  }

  getNmopCollection(client_id, site_id, type, start_date, end_date) {
    if (site_id === -1 || site_id === undefined) site_id = null;

    let url = `${API_URL}stats/debtcollection/count?client_id=${client_id}`;

    if (site_id !== null) {
      url += `&site_id=${site_id}`;
    }

    if (type !== null && type !== undefined) {
      url += `&type=${type}`;
    }
    if (start_date !== null && start_date !== undefined) {
      url += `&start_date=${start_date}`;
    }
    if (end_date !== null && end_date !== undefined) {
      url += `&end_date=${end_date}`;
    }

    return axios.get(url, { headers: authHeader() });
  }

  getBlacklistCount(client_id, site_id = null, start_date, end_date) {
    if (site_id === -1 || site_id === undefined) site_id = null;

    let url = `${API_URL}stats/blacklist/count?client_id=${client_id}`;

    if (start_date !== null || start_date !== undefined) {
      url += `&start_date=${start_date}`;
    }
    if (end_date !== null && end_date !== undefined) {
      url += `&end_date=${end_date}`;
    }
    if (site_id !== null) {
      url += `&site_id=${site_id}`;
    }

    return axios.get(url, { headers: authHeader() });
  }

  getAnprSites(client_id, site_id = null) {
    if (site_id === -1 || site_id == undefined) site_id = null;
    let url = `${API_URL}stats/anpr/count?client_id=${client_id}`;
    if (site_id !== null) {
      url += `&site_id=${site_id}`;
    }

    return axios.get(url, { headers: authHeader() });
  }

  getTabletOnlySites(client_id, site_id = null) {
    if (site_id === -1 || site_id == undefined) site_id = null;
    let url = `${API_URL}stats/tabletonly/count?client_id=${client_id}`;
    if (site_id !== null) {
      url += `&site_id=${site_id}`;
    }

    return axios.get(url, { headers: authHeader() });
  }

  getPortalOnlySites(client_id, site_id = null) {
    if (site_id === -1 || site_id === undefined) site_id = null;
    let url = `${API_URL}stats/portalonly/count?client_id=${client_id}`;
    if (site_id !== null) {
      url += `&site_id=${site_id}`;
    }

    return axios.get(url, { headers: authHeader() });
  }

  getWorstSites(client_id, start_date, end_date) {
    return axios.get(
      `https://api.varsanpr.com/api/reports/stats/worstsites?client_id=${client_id}&start_date=${start_date}&end_date=${end_date}`,
      { headers: authHeader() }
    );
  }
}

export default new StatsService();

<template>
    <div class="row">
        <h2 class="section-heading m-b-md"> {{$t('global.global', {text: 'Statistics'})}}</h2>
        <MessageCard :alertType="'indicator-info'" :alertTitle="$t('claims.new.comments')" :alertText="$t('stats.global.alert_message')" class="m-b-md"/>

        <div class="form-group col-xl-4 m-b-md" style="padding: 0">
            <label for="statsCard" class="form-label"> Go To </label>
            <select id="dropDown" class="form-select" v-model="selectedTitle">
                <option value="null" selected disabled> Select a card </option>
                <option :value="'#' + title.id" v-for="title in statsTitle" :key="title.id"> {{title.value}} </option>
            </select>
        </div>

        <div class="row">
            <stats-card v-if="!stats.escalated.loading" id="escalated" :title="$t('stats.one.title')" :statsData="stats.escalated" :desc="$t('stats.one.desc')"></stats-card>
            <stats-card id="driveoffFirstLetter" :title="$t('stats.two.title')" :statsData="stats.driveoffFirstLetter" :desc="$t('stats.two.desc')"></stats-card>
            <stats-card id="driveoffSecondLetter" :title="$t('stats.three.title')" :statsData="stats.driveoffSecondLetter" :desc="$t('stats.three.desc')"></stats-card>
            <stats-card id="nmopDebtCollection" :title="$t('stats.four.title')" :statsData="stats.nmopDebtCollection" :desc="$t('stats.four.desc')"></stats-card>
            <stats-card id="driveoffDebtCollection" :title="$t('stats.five.title')" :statsData="stats.driveoffDebtCollection" :desc="$t('stats.five.desc')"></stats-card>
            <stats-card id="blacklist" :title="$t('stats.six.title')" :statsData="stats.blacklist" :desc="$t('stats.six.desc')"></stats-card>

            <!-- <div class="row">
                <div class="col-xl-12">
                    <div class="card widget widget-stats-large">
                        <div class="row">
                            <div class="col-xl-8">
                                <div class="widget-stats-large-chart-container">
                                    <div class="card-header">
                                        <h5 class="card-title">{{$t('navigation.side.claims')}}<span class="badge badge-light badge-style-light">{{$t('sites.site.lifetime')}}</span></h5>
                                    </div>
                                    <div class="card-body" style="position: relative;">
                                        <vue-apex-charts type="bar" :options="charts.escalated.chartOptions" :series="charts.escalated.series"></vue-apex-charts>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4">
                                <div class="widget-stats-large-info-container">
                                    <div class="card-header">
                                        <h5 class="card-title">{{$t('dashboard.overview')}}</h5>
                                    </div>
                                    <div class="card-body">
                                        <p class="card-description text-muted">{{$t('dashboard.chart_description')}}</p>
                                        <ul class="list-group list-group-flush text-light">
                                            <li class="list-group-item" @click="openReports('DRIVEOFF')">{{$t('global.driveoff')}}<span class="float-end">{{claims.current.driveOff.toLocaleString()}}</span></li>
                                            <li class="list-group-item" @click="openReports('NMOP')">{{$t('global.nmop')}}<span class="float-end">{{claims.current.nmop.toLocaleString()}}</span></li>
                                            <li class="list-group-item" @click="openReports('PARKING')">{{$t('global.parking')}}<span class="float-end">{{claims.current.parking.toLocaleString()}}</span></li>
                                            <li class="list-group-item" @click="openReports('')">{{$t('sites.invoice.total')}} <span class="float-end"> {{ total_claims }}</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="row space-between">
                <div class="card col-xl-3" id="stats-card">
                    <div class="card-body">
                        <div class="card-title text-center"> {{$t('stats.seven.title')}} </div>
                        <p class="text-center"> {{$t('stats.seven.desc')}} </p>
                        <div class="bg-circle" style="margin: auto;">
                            <span> {{stats.anprSites.count}} </span>
                            <!-- {{ stats.anprSites.details}} -->
                        </div>
                    </div>
                </div>
                <div class="card col-xl-3" id="stats-card">
                    <div class="card-body">
                        <div class="card-title text-center"> {{$t('stats.eight.title')}} </div>
                        <p class="text-center"> {{$t('stats.eight.desc')}} </p>
                        <div class="bg-circle" style="margin: auto;">
                            <span> {{stats.tabletOnly.count}} </span>
                            <!-- {{stats.tabletOnly.details}} -->
                        </div>
                    </div>
                </div>
                <div class="card col-xl-3" id="stats-card">
                    <div class="card-body">
                        <div class="card-title text-center"> {{$t('stats.nine.title')}} </div>
                        <p class="text-center"> {{$t('stats.nine.desc')}} </p>
                        <div class="bg-circle" style="margin: auto;">
                            <span> {{stats.portalOnly.count}} </span>
                            <!-- {{stats.portalOnly.details}} -->
                        </div>
                    </div>
                </div>


            </div>
            <!-- <div class="card">
                <div class="card-body">
                    <div class="card-title"> {{$t('stats.seven.title')}}</div>
                    <p> Total number of ANPR sites across all clients ({{clients.length}}) <span style="float: right;"> {{stats.anprSites.count}} </span> </p>
                    <table>
                        <tr v-for="detail in stats.anprSites.details" :key="detail.index">
                            <td> client_name {{detail.cameras.length}} </td>
                        </tr>
                    </table>
                </div>
            </div> -->

        </div>
    </div>
  
</template>

<script>
import StatsCard from '../../components/StatsCard.vue';
import MessageCard from '../../components/MessageCard.vue';
import {DATES} from '../../../src/constants/constants';
import {DateTime} from 'luxon';
import statsService from '../../services/stats.service';
import VueApexCharts from 'vue3-apexcharts';

export default {
    name: 'Global.vue',
    components: {
        StatsCard,
        MessageCard,
        VueApexCharts
    },
    data() {
        return {
            // statsTitle: [
            //     'No Means of Payment to DriveOff',
            //     '1st letter sent for driveoff',
            //     '2nd letter sent for driveoff',
            //     'No Means of Payment raised to debt collection',
            //     'Driveoff raised to debt collection',
            //     'Total number of blacklisted vehicles',
            //     'Total number of ANPR sites',
            //     'Tablet only sites',
            //     'Portal only sites'
            // ],
            statsTitle: [
                {id: 'escalated', value: 'No Means of Payment to DriveOff'},
                {id: 'driveoffFirstLetter', value: '1st letter sent for driveoff'},
                {id: 'driveoffSecondLetter', value: '2nd letter sent for driveoff'},
                {id: 'nmopDebtCollection', value: 'No Means of Payment raised to debt collection'},
                {id: 'driveoffDebtCollection', value: 'Driveoff raised to debt collection'},
                {id: 'blacklist', value: 'Total number of blacklisted vehicles'},
            ],
            selectedTitle: null,
            stats: {
                escalated: {
                    this_month: 0,
                    last_month: 0,
                    this_quarter: 0,
                    last_quarter: 0,
                    this_year: 0,
                    last_year: 0
                },
                driveoffFirstLetter: {
                    this_month: 0,
                    last_month: 0,
                    this_quarter: 0,
                    last_quarter: 0,
                    this_year: 0,
                    last_year: 0
                },
                driveoffSecondLetter: {
                    this_month: 0,
                    last_month: 0,
                    this_quarter: 0,
                    last_quarter: 0,
                    this_year: 0,
                    last_year: 0
                },
                nmopDebtCollection: {
                    this_month: 0,
                    last_month: 0,
                    this_quarter: 0,
                    last_quarter: 0,
                    this_year: 0,
                    last_year: 0
                },
                driveoffDebtCollection: {
                    this_month: 0,
                    last_month: 0,
                    this_quarter: 0,
                    last_quarter: 0,
                    this_year: 0,
                    last_year: 0
                },
                blacklist: {
                    this_month: 0,
                    last_month: 0,
                    this_quarter: 0,
                    last_quarter: 0,
                    this_year: 0,
                    last_year: 0
                },
                anprSites: {
                    count: 0,
                    details: []
                },
                tabletOnly: {
                    count: 0,
                    tablet_sites: []
                },
                portalOnly: {
                    count: 0,
                    portal_sites: []
                }
            },
            dates: DATES,
            clients: [],
            charts: {
                escalated: {
                    chartOptions: {
                        chart: {
                            id: 'EscalatedClaims'
                        },
                        xaxis: {
                            
                        },
                        plotOptions: {
                             bar: {
                            horizontal: false,
                            columnWidth: '55%',
                            endingShape: 'rounded',
                            borderRadius: 10
                            }
                        },
                        stroke: {
                            show: true,
                            width: 2,
                            colors: ['transparent']
                        },
                        tooltip: {
                            theme: 'dark',
                            x: {
                                show: true,
                                formatter: function(timestamp) {
                                    return DateTime.fromMillis(timestamp).toFormat('MMMM yyyy')
                                }
                            }
                        }
                    },
                    series: []
                }
            }
        }
    },
    mounted() {
        this.clients = this.$store.state.auth.user.clients;
        this.getEscalated();
        this.getDriveoffFirstLetter();
        this.getDriveoffSecondLetter();
        this.getBlacklistCount();
        this.getAnprCount();
        this.getTabletOnlyCount();
        this.getPortalOnlyCount();  
        this.getNmopCollection();

        let dropDown = document.getElementById('dropDown');
        dropDown.onchange = function() {
            if(this.selectedIndex !== 0) {
                window.location.href = this.value;
            }
        }
    },
    methods: {
        async getEscalated() { 
            for await (let client of this.clients) {                                                                                                                                             
                for(let stat in this.stats.escalated) {
                    statsService.getEscalated(client.id, null, this.dates[stat].start_date, this.dates[stat].end_date)
                    .then(response => {
                       this.stats.escalated[stat] += response.data.claim_count;
                    //    this.charts.escalated.series.push({data: this.stats.escalated[stat]});
                    })
                    .catch(error => {
                         this.$error(this.$t('Failed to load escalated claims count'), error);
                    })
                }
            }
        },
        async getDriveoffFirstLetter() {
            for await (let client of this.clients) {
                for(let stat in this.stats.driveoffFirstLetter) {
                    statsService.getLetterCount(client.id, null, 'driveoffnotice', this.dates[stat].start_date, this.dates[stat].end_date)
                    .then(response => {
                       this.stats.driveoffFirstLetter[stat] += response.data.claims.length;
                    })
                    .catch(error => {
                         this.$error(this.$t('Failed to load driveoff first letter count'), error);
                    })
                }
            }
        },
        async getDriveoffSecondLetter() {
            for await (let client of this.clients) {
                for(let stat in this.stats.driveoffSecondLetter) {
                    statsService.getLetterCount(client.id, null, 'driveoffsecond', this.dates[stat].start_date, this.dates[stat].end_date)
                    .then(response => {
                       this.stats.driveoffFirstLetter[stat] += response.data.claims.length;
                    })
                    .catch(error => {
                        this.$error(this.$t('Failed to load driveoff second letter count'), error);
                    })
                }
            }
        },
        async getNmopCollection() {
            for await (let client of this.clients) {
                for(let stat in this.stats.nmopDebtCollection) {
                    statsService.getNmopCollection(client.id, null, 'nmop', this.dates[stat].start_date, this.dates[stat].end_date)
                    .then(response => {
                       this.stats.nmopDebtCollection[stat] += response.data.claim_count;
                    })
                    .catch(error => {
                        this.$error(this.$t('Failed to load driveoff second letter count'), error);
                    })
                }
            }
        },
        async getdriveoffCollection() {
            for await (let client of this.clients) {
                for(let stat in this.stats.driveoffDebtCollection) {
                    statsService.getNmopCollection(client.id, null, 'driveoff', this.dates[stat].start_date, this.dates[stat].end_date)
                    .then(response => {
                       this.stats.driveoffDebtCollection[stat] += response.data.claim_count;
                    })
                    .catch(error => {
                        this.$error(this.$t('Failed to load driveoff second letter count'), error);
                    })
                }
            }
        },
        async getBlacklistCount() {
            //get data for each clients
            for await(let client of this.clients) {
                //data for single client 
                for(let stat in this.stats.blacklist) {
                    statsService.getBlacklistCount(client.id, null, this.dates[stat].start_date, this.dates[stat].end_date)
                    .then(response => {
                        this.stats.blacklist[stat] += response.data.blacklist_count;
                    })
                    .catch(error => {
                        this.$error(this.$t('Failed to load blacklisted vehicle count'), error);
                    })
                }
            }
        },
        async getAnprCount() {
            for await (let client of this.clients) {
                statsService.getAnprSites(client.id)
                .then(response => {
                    this.stats.anprSites.count += response.data.camera_count;
                    this.stats.anprSites.details.push({client: client.id, cameras: response.data.cameras});
                })
                .catch(error => {
                    this.$error(this.$t('Failed to load ANPR count'), error);
                })
            }
        },
        async getTabletOnlyCount() {
            for await (let client of this.clients) {
                statsService.getTabletOnlySites(client.id)
                .then(response => {
                    this.stats.tabletOnly.count = response.data.tablet_count;
                    this.stats.tabletOnly.tablet_sites = response.data.tablet_sites;
                })
                .catch(error => {
                    this.$error(this.$t('Failed to load tablet count'), error);
                })
            }
        },
        async getPortalOnlyCount() {
            for await (let client of this.clients) {
                statsService.getPortalOnlySites(client.id)
                .then(response => {
                    this.stats.portalOnly.count = response.data.portal_count;
                    this.stats.portalOnly.portal_sites = response.data.portal_sites;
                })
                .catch(error => {
                    this.$error(this.$t('Failed to load portal count'), error);
                })
            }
        },
        clientName(client_id) {
            return this.clients.filter(client => client.id === client_id);
        }
    },
    computed: {
        client_name: function() {
            let name = (client_id) => {this.clients.filter(client => {
                if(client.id === client_id) return client.name;
            })}
        }
    }
}
</script>

<style scoped>

    .row {
        scroll-behavior: smooth;
    }
</style>
import {DateTime} from 'luxon';
let today = DateTime.local().startOf('day');

export const CLAIM_EVIDENCE_FILE_TYPES = [
  {
    id: 'till_receipt',
    name: 'Till Receipt'
  },
  {
    id: 'vehicle_image',
    name: 'Vehicle Image'
  },
  {
    id: 'notice',
    name: 'Notice'
  },
  {
    id: 'additional_information',
    name: 'Additional Information'
  },
  {
    id: 'dvla_response',
    name: 'DVLA Response to Enquiry'
  },
  {
    id: 'signature_image',
    name: 'Signature'
  },
  {
    id: 'customer_image',
    name: 'Image of Customer'
  },
  {
    id: 'transfer_of_liability',
    name: 'Transfer of Liability'
  }
]

function getLastQuarter() {
  let last_quarter = today.quarter - 1;
  if(last_quarter === 0) last_quarter = 1;

  let start_date, end_date;
  if(last_quarter === 1) {
    start_date = DateTime.local().set({month: 1}).startOf('month').toFormat('yyyy-MM-dd');
    end_date = DateTime.local().set({month: 3}).endOf('month').toFormat('yyyy-MM-dd');
  }
  if(last_quarter === 2) {
    start_date = DateTime.local().set({month: 4}).startOf('month').toFormat('yyyy-MM-dd');
    end_date = DateTime.local().set({month: 6}).endOf('month').toFormat('yyyy-MM-dd');
  }
  if(last_quarter === 3) {
    start_date = DateTime.local().set({month: 7}).startOf('month').toFormat('yyyy-MM-dd');
    end_date = DateTime.local().set({month: 9}).endOf('month').toFormat('yyyy-MM-dd');
  }
  if(last_quarter === 4) {
    start_date = DateTime.local().set({month: 10}).startOf('month').toFormat('yyyy-MM-dd');
    end_date = DateTime.local().set({month: 12}).endOf('month').toFormat('yyyy-MM-dd');
  }

  return {start_date, end_date};
}

export const DATES = {
  this_week: {
    start_date: today.startOf('week').startOf('day').toFormat('yyyy-MM-dd'),
    end_date: today.endOf('week').endOf('day').toFormat('yyyy-MM-dd')
  },
  this_month: {
    start_date: today.startOf('month').toFormat('yyyy-MM-dd'),
    end_date: today.endOf('month').toFormat('yyyy-MM-dd')
  },
  last_month: {
    start_date: today.minus({month: 1}).startOf('month').startOf('day').toFormat('yyyy-MM-dd'),
    end_date: today.minus({month: 1}).endOf('month').endOf('day').toFormat('yyyy-MM-dd')
  },
  this_quarter: {
    start_date: today.startOf('quarter').startOf('day').toFormat('yyyy-MM-dd'),
    end_date: today.endOf('quarter').endOf('day').toFormat('yyyy-MM-dd')
  },
  last_quarter: {
    start_date: getLastQuarter().start_date,
    end_date: getLastQuarter().end_date
  },
  this_year: {
    start_date: today.startOf('year').startOf('day').toFormat('yyyy-MM-dd'),
    end_date: today.endOf('year').endOf('day').toFormat('yyyy-MM-dd')
  },
  last_year: {
    start_date: today.minus({year: 1}).startOf('year').startOf('day').toFormat('yyyy-MM-dd'),
    end_date: today.minus({year: 1}).endOf('year').endOf('day').toFormat('yyyy-MM-dd')
  }
}

